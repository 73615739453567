.rightTab{
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;
    padding:5px;
}
.rightTabDetails{
    padding-left:5%;
    display: grid;

}