.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login-title {
    color: #050505;
    font-family: "Open Sans-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 40px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
}

.login-inputs-container,
.login-new-account {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%

}

.login-buttons-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.login-fb {
    padding-left: 10px;
    padding-right: 20px;
    font-size: 18px;
}

.forgot-password {
    ;
}