
  .chatBox {
    height: 100%;
  }
  
  .chatBoxWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding-left: 8px;
    padding-top: 16px;
    max-height: 90vh;
  }
  
  .chatBoxTop {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .chatBoxBottom {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px
  }



  