.editUserDetails{
    background-color: white;
    padding: 10px;
}
.title{
    justify-content: center;
}
.formFields{
    display:grid;
}
.formButtons{
    justify-content: space-between;
    display: flex;
}
