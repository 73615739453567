.announcements {
    margin-top: 5px;
}

.announcements-title {
    color: #ffffff;
    padding: 5px;
}

.announcement {
    margin-top: 8px;
    padding: 5px;
}

.announcement-title {
    font-weight: bold;
    font-family: "Open Sans-Bold", Helvetica;
    color: #ffffff;
}

.announcement-label {
    color: #ffffff;
}
