.centerLeftTab{
    width: 100%;
    background-color: white;
    padding-right: 10px;
    border-radius: 5px;
}
.leftTabContainer{
    padding: 20px;
    padding-top: 5px;
}
.leftTabLinks{
    display: grid;
}
.lineTitle{
    display: flex;
    justify-content: space-between;
}
.editForm{
    display: inline-grid;
    padding: 2%;
}