.youtube-preview {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.youtube-thumbnail {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.youtube-thumbnail:hover {
    opacity: 0.9;
}

.youtube-info {
    padding: 15px;
}

.youtube-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}

.youtube-description {
    font-size: 14px;
    color: #555;
    line-height: 1.4;
    max-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.youtube-link {
    text-decoration: none;
    color: #3b5998;
    font-weight: bold;
}