.friend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userTabs {
    background-color: white;
}

.tabLabel {
    font-size: small;
}

.tabPanel {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding-top: 2px !important;
}

.centerTabPanel {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding-top: 10px !important;
}

.friendTabPanel,
.cvTabPanel,
.friendsTabPanel,
.groupsTabPanel,
.fileTabPanel,
.discriminationTabPanel {
    width: 100%;
    display: flex;
    gap: 8px;
}
