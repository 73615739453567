.reset-password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20%;
}

.reset-password-title {
    color: #050505;
    font-family: "Open Sans-Light", Helvetica;
    font-size: 20px;
    font-weight: 300;
    height: 40px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
}

.reset-password-inputs-container,
.reset-password-new-account {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.reset-password-buttons-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

