.post{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.postContainer{

    background-color: white;
    border-radius: 13px;
    padding: 5px;
    justify-content: center;

}
.postTitle{
    display: flex;
    justify-content: space-between;
    margin: 2%;
}
.crossBtn{
    justify-content: space-between;
}
.globalTag{
    margin-right:3px;
}
.postButtons{
    display: flex;
    justify-content: space-between;
    padding: 1%;
}
.iconBtns{
    display: flex;
}
.videoPreviewContainer{
    display: grid;
}