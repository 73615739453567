.jobContainer{
    margin-top: 15px;
    width:100%;
}
.searchBarContainer{
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 2px solid #27293D;
    background: #FFF;
    display:flex;
}
.searchField{
    display: inline-flex;
    width: 40%;
    height: 45px;
    /* padding: 13.5px 20px; */
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.searchBtn{
    display: flex;
    /*width: 185px;*/
    width:20%;
    height: 46px !important;
    /*padding: 2%!important;*/
    border-radius: 0px 40px 40px 0px !important;
}

.resultsContainer{
    /*margin-top: 2%;*/
    display: flex;
    width: 100%;
}
.result{
    /*width:40%;*/
    width:100%;
}
.cardTitle{
    display: flex;
    justify-content: space-between;
}
.jobInfo{
    margin-top: 2%;
    border-radius: 20px;
    border: 1px solid #DDD;
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
}
.jobHeader{
    margin-top: 10%;
    padding-left: 3%;
    display: flex;
}
.jobTitle{
    width:49%;
}
.jobButtons{
    display: flex;
    /*width: 49%;*/
    justify-content: flex-end;
}
.tagsContainer{
    display: flex;
    padding-left:3%;
}
.jobTag{
    border-radius: 10px;
    border: 1px solid #27293d;
    /*background: #FFF;*/
    padding: 5px;
    /*margin-right: 5px;*/
    /*margin-top:10px;*/
    background-color: #27293d;
    margin:5px;
}
.fullDescriptionTitle,
.fullDescription{
    padding:3%;
}
.tags{
    display: -webkit-box;
}

.menuJob{
    display:flex;
}