.accountName {
    display: flex;
    padding-left: 2px;
    color: #020700;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 15px;
    font-weight: 400;
    max-height: 20px;
    line-height: 20px;
}
#account-menu-header .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0)
}