.searchResultsContainer{
    border-radius: 20px;
    border: 1px solid #DDD;
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    /*margin-left: 30%;*/
    /*margin-right: 30%;*/
    margin-top: 2%;
    padding:10px;
}

.users,
.companies,
.groups{
    margin-bottom:2%;
}
.userName{
    color: #050505;
    font-family: Open Sans-Regular;
    font-size: large;
    font-style: normal;
    font-weight: 400;
}
.memberDate{
    color: #616161;
    font-family: Open Sans-Regular;
    font-size: small;
    font-style: normal;
    font-weight: 400;
}