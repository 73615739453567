.groupCover_png{
    width:100%
}
.groupDetails{
    background-color: white;
    display: -webkit-inline-flex;
    vertical-align: top;
    margin-left: 2%;
    width: 95%;
    justify-content: space-between;
}
.groupDetailsLabels{
    width:100%
}
.groupDetailsLabel{
    display: flex;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: x-large;
}
.clickIcon{
    width: 24px;
    padding-left:2px;
}