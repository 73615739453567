.icons {
    display: flex;
    max-height: 30px;
}
.active{
    background-color: #000000 !important;
}
.active path{
    fill: white;
}
.active.home-img{
    background-color: #000000 !important;

}
.active.home-img path{
     fill: white;
 }
.home-img {

    padding:5px;
    border-radius: 50%;
    height: 30px;
}

.secondBarIcons {
    display: contents;
    max-height: 30px;
}