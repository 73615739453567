.centerLeftTab{
    width: 100%;
    background-color: white;
    padding-right: 10px;
    border-radius: 5px;
}

.leftTabContainer{
    padding: 20px;
}
.leftTabLinks{
    display: grid;
}
memberName{
    font-family: Open Sans;
    font-size: small;
}