.profileImages{
    background-color: white;
}
.userCover_png{
    width:100%;
    height:250px;
    /*margin-top:-16%;*/
}
.userImage_png{
    /*border-radius: 50%;*/
    /*border: 1px white solid;*/
    /*width: 40%;*/
    /*transform: translate(-22%,80%);*/
    /*margin-left: 5px;*/
    border-radius: 50%;
    border: 1px white solid;
    width: 140px;
    height:140px;
    transform: translate(0%,-50%);
    margin-left: 5px;
}

.details{
    background-color: white;
    display: -webkit-inline-flex;
    vertical-align: top;
    margin-left: 2%;
    width: 80%;
}

.userDetailsContainer{
margin-top: -12%;
}

.userDetails,
.headerText{
    background-color: white;
    display: flex;
    vertical-align: top;
    /*margin-left: 17%;*/
    /*padding-right:1%;*/
    width: 100%;
    justify-content: space-between;
}
.userDetailsLabels{
    /*width:70%*/
    margin-left: 10px;
}
.userDetailsLabel{
    font-family: "Open Sans-Regular", Helvetica;
    font-size: x-large;
}
.extraDetails{
    font-size: small;
}
.userDetailsButtons{
    /*display: flex;*/
    display:inline-table;
    justify-content: end;
    gap: 8px;
    padding: 0px 0px 8px 8px;
    height: 35px;
    text-align: -webkit-right;
}
.headerPoints{
    color: #27293D;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: small;
    font-style: normal;
    font-weight: 400;
}
.extraDescriptionEditor{
    color: #020700;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: smaller;
    width: 80%;
    /*margin-left: 16%;*/
    margin-top: 2%;
    margin-left: 10px;
}
/*.imageButton {*/
/*    !*pointer-events: none;*!*/
/*    !*opacity: 0.3;*!*/
/*    width: 40%;*/
/*    transform: translate(-22%,80%);*/
/*    margin-left: 5px;*/
/*}*/


.imageButton:not(.nohover):hover {
    border: 1px solid #0814bf;
    width: 60%;
    transform: translate(-35%,-50%);
    margin-left: 5px;
}
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
}

.fullscreen-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
