
.memberTabLabel{
    font-size:smaller;
    color:#2682D5 !important;
}
.memberSubTab{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: space-between!important;
    width: 100% !important;
    padding-left: 10px !important;
    padding-top: 20px !important;
}

.searchBar{
    display: flex;
    width: 221px;

    padding: 4.72px 9px 4.72px 17px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    transform: translate(220%,0);
}
.searchBarTitle{
    border-radius: 20px;
    border: 1px solid #EDEDED;
    background: rgba(0, 0, 0, 0.04);
}