.ads {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.ads .ad {
    width: 50%;
    cursor: pointer;
}

.ad img {
    max-width: 100%;
}