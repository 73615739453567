.profileImages{
    background-color: white;
}
.companyCover_png{
    width:100%
}
.companyImage_png{
    border-radius:50%;
    border: 1px white solid;
    width: 15%;
    transform: translate(0%,-50%);
    margin-left: 5px;
}
.companyDetails{
    background-color: white;
    display: -webkit-inline-flex;
    vertical-align: top;
    margin-left: 2%;
    width: 80%;
    justify-content: space-between;
}
.companyDetailsLabels{
    width:100%
}
.companyDetailsLabel{
    display: flex;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: x-large;
}
