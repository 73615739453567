.postingContainer{
    width: 100%;
    /*height: 82px;*/
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E0E0E0;
    background: #FFF;
    border-radius: 8px;
    margin-top: 15px;
}
.postingDscr {
    display: grid;
    width: 100%;
}

.postingUserName{
    font-size:medium;
}
.postingPublicDate{
    font-size:small;
}
.dscrField{
    margin-left:20px;
}