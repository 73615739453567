.videoPreview{
    display: flex;
    padding-top:5px;
    padding-bottom:5px;
    justify-content: center;
}
.videoImage{
    width: 180px;
    height: 180px;
    border-radius: 20px 0px 0px 20px;
}
.videoDetails{
    display: flex;
    width: 506px;
    height: 180px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 2px solid #6161614a;
    border-radius: 0px 20px 20px 0px;
}
.videoTitle{
display: flex;
justify-content: space-between;
width: 100%;
}
.buttonStyle{
    background: white;
    border: 0;
}