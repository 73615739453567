.centerLeftTab {
    width: 60%;
    background-color: white;
    padding-right: 10px;
    border-radius: 5px;
}

.groupsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding-left: 10px;
    padding-top: 20px;
}

.tabTitle {
    display: flex;
    justify-content: space-evenly;
    width: auto;
}
