.own {
    display: inline-flex;
    justify-content: flex-end;
    margin-left: auto;
    background-color: rgb(36, 124, 230);
    padding: 15px;
    color: white;
    border-radius: 30px 10px 30px 30px;
    max-width: 400px;
}
  
.notOwn {
    display: inline-flex;
    margin-right: auto;
    max-width: 400px;
    background-color: rgb(223, 222, 222);
    color: black;
    padding: 15px;
    border-radius: 10px 30px 30px 30px;
}

