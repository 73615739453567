.rsss {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.rsss .rss {
    width: 40%;
    cursor: pointer;
    color:#050505;
}

.rss img {
    max-width: 100%;
}