
.postComponentContainer{
    width: 100%;
    /*height: 82px;*/
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    /*border: 1px solid #E0E0E0;*/
    background: #FFF;
    border-radius: 8px;
    margin-top: 15px;
}
.postComponent{
    display: flex;
    width: 100%;
    padding: 5px;
    padding-bottom: 15px;
}
.thinkingInput{
    display: flex;
    /*width: 482px;*/
    height: 48px;
    /*padding: 15px 311.82px 13px 25px;*/
    align-items: center;
    border-radius: 24px;
    border: 1px solid #F3F3F3;
    background: #F3F3F3;

}


