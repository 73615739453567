.espa-banner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.espa-logo {
    align-self: start;
    max-width: 400px;
    width: 100%;
}