
.fab_envelop:hover{
    background-color:  rgb(255, 255, 255) !important;
}
.class-4 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    padding: 2px;
}

.class-4 h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
}

.class-4 h3 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
}
