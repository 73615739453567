.noAuth-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    min-width: 280px;
}

.noAuth-card {
    width: 100%;
    max-width: 1024px;
    margin-bottom: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    align-items: center;
    display: inline-flex;
}
