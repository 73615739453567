.cropContainer{
    position: relative;
    width: 100%;
    height: 400px;
    background: #333;
}
.modalContainer{
    background-color: white;
    padding: 5px;
    width: 100%;
}
.chooseImage{
    width:100%;
    height:20%;
    display: flex;
    justify-content: center;
}
.modalButtons{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
