.footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1024px;
    white-space: nowrap;
    padding: 0 4.39px 0 0;
}

.footer a {
    text-decoration: none;
}

