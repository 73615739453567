.notification a{
    text-decoration: none;
}
.MuiListItemIcon-root{
    min-width: 0px !important;
}
.listHeader{
    display:flex;
    width: 100%;
    justify-content: space-between;
    white-space: nowrap;
}
.notificationsBtns{
    justify-content: end;
    display: flex;
    padding:5px;
}
