
t-wrapper {
    color: #27293d;
    font-family: "Open Sans-Regular", Helvetica;
    /*font-size: 15px;*/
    font-size: medium;
    padding-left: 5px;
}
.choiceBtn{
    width:100% !important;
    justify-content: start !important;
}
