.globalSearchBox {
    align-self: center;
    background-color: #0207000A;
    border-radius: 15px 15px 15px 15px;
    display: flex;
}

.search-png {
    margin-right: 5px;
    margin-left: 2%;
}